import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  image?: string
  label?: string
  title?: string
}

export const Item = memo(function Item({ image, label, title }: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <Wrapper>
        <Text dial={5}>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title>{title}</Title> : null}
        </Text>
      </Wrapper>
      <Background>
        {image ? <Image style={{ backgroundImage: `url(${image})` }} /> : null}
      </Background>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 40%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  position: relative;
  &:nth-of-type(1),
  &:nth-of-type(6) {
    > div {
      &:first-of-type {
        order: 2;
        &:before {
          border-right: 0.9375rem solid
            ${({ theme }) => theme.colors.variants.neutralLight1};
          border-left: 0;
          right: auto;
          left: -0.9375rem;
        }
      }
      &:last-of-type {
        padding-bottom: 100%;
      }
    }
  }
  &:nth-of-type(2),
  &:nth-of-type(7) {
    width: 20%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    > div {
      width: 100%;
      height: 50%;
      &:first-of-type {
        &:before {
          border-left: 1.125rem solid transparent;
          border-right: 1.125rem solid transparent;
          border-top: 0.9375rem solid
            ${({ theme }) => theme.colors.variants.primaryLight1};
          top: auto;
          bottom: -2.0625rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &:nth-of-type(3),
  &:nth-of-type(8) {
    width: 40%;
    > div {
      width: 100%;
      height: 50%;
      &:first-of-type {
        order: 2;
        padding-bottom: 50%;
        &:before {
          border-left: 1.125rem solid transparent;
          border-right: 1.125rem solid transparent;
          border-bottom: 0.9375rem solid
            ${({ theme }) => theme.colors.variants.neutralLight1};
          top: -2.0625rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &:nth-of-type(4),
  &:nth-of-type(9) {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    > div {
      &:first-of-type {
        &:before {
          border-left-color: ${({ theme }) =>
            theme.colors.variants.primaryLight1};
        }
      }
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    &:nth-of-type(1),
    &:nth-of-type(6) {
      > div {
        &:first-of-type {
          &:before {
            border-left: 1.125rem solid transparent;
            border-right: 1.125rem solid transparent;
            border-top: 0.9375rem solid
              ${({ theme }) => theme.colors.variants.neutralLight1};
            top: auto;
            bottom: -2.0625rem;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        &:last-of-type {
          order: 2;
          padding-bottom: 50%;
        }
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(7) {
      width: 100%;
      > div {
        height: auto;
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(8) {
      width: 100%;
      > div {
        height: auto;
        &:first-of-type {
          padding-bottom: 0;
          &:before {
            border-left: 1.125rem solid transparent;
            border-right: 1.125rem solid transparent;
            border-top: 0.9375rem solid
              ${({ theme }) => theme.colors.variants.neutralLight1};
            border-bottom: 0;
            top: auto;
            bottom: -0.9375rem;
          }
        }
        &:last-of-type {
          order: 2;
        }
      }
    }
    &:nth-of-type(4),
    &:nth-of-type(9) {
      > div {
        &:first-of-type {
          &:before {
            border-left: 1.125rem solid transparent;
            border-right: 1.125rem solid transparent;
            border-top: 0.9375rem solid
              ${({ theme }) => theme.colors.variants.primaryLight1};
            top: auto;
            bottom: -2.0625rem;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
`

const Wrapper = styled(FlexBox)`
  width: 50%;
  position: relative;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 1.125rem solid transparent;
    border-left: 0.9375rem solid
      ${({ theme }) => theme.colors.variants.neutralLight1};
    border-bottom: 1.125rem solid transparent;
    position: absolute;
    top: 50%;
    right: -0.9375rem;
    transform: translateY(-50%);
    z-index: 2;
  }

  @media (max-width: 1023px) {
    width: 100%;
    &:before {
      border-left: 1.125rem solid transparent;
      border-right: 1.125rem solid transparent;
      border-top: 0.9375rem solid
        ${({ theme }) => theme.colors.variants.neutralLight1};
      top: auto;
      bottom: -2.0625rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const Text = styled(FlexBox)`
  padding: 0 3.75rem;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    margin: auto;
    padding: 4.75rem 1.9375rem;
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
    transform: none;
  }
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.625rem;
`

const Background = styled.div`
  width: 50%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding-bottom: 50%;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  description?: string
  cta?: ButtonProps
  label?: string
  items?: ItemProps[]
  title?: string
}

export const Mosaic = memo(function Mosaic({
  description,
  cta,
  label,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>
              <TitleContent>{title}</TitleContent>
            </Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Wrapper>
      <List row wrap stretch>
        {items.slice(0, 10).map((item, index) => (
          <Item key={index} {...item} />
        ))}
        {cta ? (
          <CTA
            ref={ref}
            animate={isVisible ? 'visible' : 'hidden'}
            transition={{
              duration: 0.5,
            }}
            variants={variants}
          >
            <Button {...cta} />
          </CTA>
        ) : null}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 10rem auto 11.75rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`

const Wrapper = styled.div`
  margin: auto;
  padding: 0 6vw;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    padding: 0 1.9375rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  &:before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0 auto 1.25rem;
    transform: rotate(45deg);
  }
`

const Title = styled.h2`
  max-width: 52.778vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  margin: auto;
  padding: 0 8.75vw;
  position: relative;
  &:before {
    content: '';
    width: 78.194vw;
    height: 25vh;
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    border-bottom: 0;
    position: absolute;
    top: 1.875rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 2.4375rem;
    padding: 0;
    &:before,
    &:after {
      display: none;
    }
  }
`

const TitleContent = styled.span`
  position: relative;
  z-index: 2;
`

const Description = styled.div`
  max-width: 48.611vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin: 1.875rem auto 0;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const List = styled(FlexBox)`
  margin-top: 4rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const CTA = styled(motion.div)`
  width: 20%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`
